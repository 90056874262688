<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('teachmygroups.groupadmin')}}
                    
                    div.text-center.mx-auto
                        router-link.btn.btn-outline-dark(to="/teacher/createGroup") {{ $t('teachmygroups.creategroup')}}
                    
                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                              li.breadcrumb-item
                                router-link(to="/teacher/teacherhome")  {{ $t('teachmygroups.teadashboard')}}
                              li.breadcrumb-item.active(aria-current="page") {{ $t('teachmygroups.mygroups')}}
                              
                div.row.mt-5.pb-5
                    div.col-md-11
                        div.card
                            div.card-header
                                h3.card-title {{ $t('teachmygroups.mygroups')}}
                            div.card-body
                                table.table.table-bordered
                                    thead
                                        tr
                                            th {{ $t('teachmygroups.name')}} 
                                            th {{ $t('teachmygroups.code')}}
                                            th {{ $t('teachmygroups.startdate')}}
                                            th {{ $t('teachmygroups.finaldate')}}
                                            th {{ $t('teachmygroups.available')}}
                                            th {{ $t('teachmygroups.used')}}
                                            th {{ $t('teachmygroups.action')}}
                                    tbody
                                        tr(v-for="group in groupList")
                                            td {{ group.sGroupName }}
                                            td {{ group.sKeyGroup }}
                                            td {{ group.dUTCStartDate }}
                                            td {{ group.dUTCFinishDAte }}
                                            td {{ group.iAvailableInventory }}
                                            td {{ group.iUsedInventory }}
                                            td
                                                a(href="#" @click="$router.push('/teacher/GroupDetails/' +  group.idGroup)" )
                                                    svg.bi.bi-patch-check(xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16")
                                                        path(fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z")
                                                        path(d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z")                                            
                                                a(href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Descargar Certificados" @click="getCertificates(group.idGroup)")
                                                    svg.feather.feather-edit-2.text-success(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                        path(d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z")
</template>

<script>
/* eslint-disable */
import DependingCentersComponent from '@/components/Admin/DependingCentersComponent'
export default {
    name: 'MyGroups',
    components: {
    },
    data() {
        return {
            groupList: [],
        }
    },
    methods: {
        async getCenterList() {
            await axios.get(apiURL + 'Group/getTeacherGroups?idTeacher=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.groupList = response.data.groupsList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },

        async getCertificates(idGroup) {
            this.openLoading(this.$t('msg.generatingcertif'))
            await axios.get(apiURL + 'Student/getGroupCertificates?idGroup='+ idGroup, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0)
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }

                window.location = "data:application/zip;base64," + response.data.base64Report
                Swal.close()
            } )
            .catch( (error)  => {
                console.log(error)
                Swal.close()
            } )  
        }
    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
        this.getCenterList()
    },
}
</script>
